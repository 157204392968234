<template>
  <div class="EmployeesManagement">
    <div>
      <div
        @click="goBack"
        style="
          color: #282728;
          background: #fff;
          padding: 0 15px;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
        "
      >
        返回上级
      </div>

      <div
        style="
          background: #fff;
          height: 45px;
          box-sizing: border-box;
          padding: 0 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-input
          class="input-style-none"
          v-model="searchVal"
          size="small"
          placeholder="请输入姓名或手机号"
        ></el-input>
        <div
          @click="getEmployeesList"
          style="
            font-weight: 400;
            width: 96px;
            height: 32px;
            border-radius: 4px;
            background: #4372fc;
            color: #fff;
            font-size: 14px;
            line-height: 32px;
            text-align: center;
          "
        >
          搜索
        </div>
      </div>

      <div
        style="
          height: 57px;
          padding: 0 17px;
          color: #3c3c3c;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="display: flex; align-items: center">
          <div>共</div>
          <div style="color: #f2963d; margin: 0 5px">{{ total }}</div>
          <div>人</div>
        </div>
        <div @click="goTeamManagement" style="color: #4372fc; font-size: 14px">
          战队管理 >
        </div>
      </div>

      <div style="height: 530px; overflow-y: auto; font-weight: 400">
        <div
          v-for="(item, index) in employeesList"
          :key="index"
          style="
            margin-top: 11px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            padding: 12px 16px;
          "
        >
          <div style="display: flex; align-items: center">
            <!-- <div style="width: 53px; height: 53px; background: #4372fc; margin-right: 12px; border-radius: 50%;"></div> -->
            <img
              :src="item.avatar"
              alt=""
              style="
                width: 53px;
                height: 53px;
                margin-right: 12px;
                border-radius: 50%;
              "
            />

            <div style="font-size: 12px">
              <div style="color: #282728; font-size: 14px">
                {{ item.realname }}
              </div>
              <div style="color: #2d5add">{{ item.telphone }}</div>
              <div style="color: #484848">微信昵称：{{ item.nickname }}</div>
              <div style="color: #bdbfc0">{{ item.createtime }} 注册</div>
            </div>
          </div>

          <div
            @click="handleDeleteEmployees(item)"
            style="color: #e9411a; font-size: 13px; align-self: flex-start"
          >
            删除
          </div>
        </div>

        <div
          style="
            color: #8d8d8d;
            font-size: 13px;
            margin: 26px 0;
            text-align: center;
            font-weight: bold;
          "
        >
          没有更多了
        </div>
      </div>
    </div>

    <!-- 是否显示操作提示 -->
    <div v-if="isShowOperationHint">
      <div class="mask"></div>
      <div
        style="
          z-index: 3000;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 323px;
          height: 200px;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 18px 29px;
          background: #fff;
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            margin-top: 45px;
          "
        >
          <div style="color: #393939">{{ operationHintContent }}</div>
          <div style="color: #4372fc">"{{ currentSelectInfo.realname }}"</div>
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            left: 0;
            bottom: 0;
            box-sizing: border-box;
            width: 100%;
            padding: 18px 29px;
          "
        >
          <div
            @click="isShowOperationHint = false"
            style="
              width: 116px;
              height: 44px;
              border-radius: 5px;
              box-sizing: border-box;
              border: 1px solid #e4e4e4;
              color: #3e3e3e;
              font-size: 16px;
              text-align: center;
              line-height: 42px;
            "
          >
            取 消
          </div>
          <div
            @click="handleConfirmDelete"
            style="
              width: 116px;
              height: 44px;
              border-radius: 5px;
              box-sizing: border-box;
              border: 1px solid #e31a1a;
              color: #e31a1a;
              font-size: 16px;
              text-align: center;
              line-height: 42px;
            "
          >
            删 除
          </div>
        </div>
      </div>
    </div>

    <!-- 提示 -->
    <div v-if="isShowHint">
      <div
        style="
          z-index: 3001;
          color: #fff;
          font-weight: 400;
          font-size: 12px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
          padding: 10px 15px;
        "
      >
        {{ hintContent }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeesManagement",
  components: {

  },
  data() {
    return {
      // 搜索的值
      searchVal: '',
      // 活动id
      id: "",
      // 员工列表
      employeesList: [],
      // 是否显示提示
      isShowHint: false,
      // 提示内容
      hintContent: '',
      // 是否显示操作提示
      isShowOperationHint: false,
      // 操作提示的内容
      operationHintContent: '',
      // 当前选择的员工信息
      currentSelectInfo: {},
      // 员工数量
      total: 0,
    }
  },
  mounted() {
    this.id = parseInt(this.$route.query.id);
    this.getEmployeesList();
  },
  methods: {
    /** 处理确认删除的操作 */
    handleConfirmDelete() {
      this.$axios.post("/admins/delStaff", {
        event_id: this.currentSelectInfo.event_id,
        member_id: this.currentSelectInfo.id,
      }).then(() => {
        this.getEmployeesList();
        this.showMessage("删除成功");
        this.isShowOperationHint = false;
      }).catch(error => {
        this.showMessage(error)
      });
    },

    /** 处理删除员工的操作 */
    handleDeleteEmployees(item) {
      this.currentSelectInfo = item;
      this.operationHintContent = "是否确认删除员工";
      this.isShowOperationHint = true;
    },

    /** 获取员工列表 */
    getEmployeesList() {
      this.$axios.post("/admins/getStaffList", {
        event_id: this.id,
        kw: this.searchVal,
      }).then(res => {
        this.employeesList = res.data;
        this.total = res.total;
      });
    },

    /** 去战队管理页面 */
    goTeamManagement() {
      this.$router.push({ path: '/teamManagement?id=' + this.id });
    },

    /** 显示提示 */
    showMessage(message) {
      this.hintContent = message;
      this.isShowHint = true;
      const timeout = setTimeout(() => {
        this.isShowHint = false;
        clearTimeout(timeout);
      }, 1000);
    },

    /** 返回上一页 */
    goBack() {
      this.$router.back();
    },
  }
};
</script>

<style scoped lang="scss">
.EmployeesManagement {
  background: #f5f5f5;
  min-height: 100vh;
  height: 100%;
  font-weight: bold;
}

.input-style-none {
  ::v-deep input {
    background: #f8f8f8;
    border: none;
  }
}
</style>
